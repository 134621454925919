import MediaComposition from '../model/MediaComposition.js';

/**
 * @ignore
 */
class DataProviderService {
  constructor(hostName = 'il.srgssr.ch') {
    this.setIlHost(hostName);
  }

  setIlHost(hostName) {
    this.baseUrl = `${hostName}/integrationlayer/2.0/`;
  }

  /**
   * MediaList facility method .
   * @param {String} url
   */
  static fetchMediaList(url) {
    return fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json()
            .then((data) => {
              const { mediaList, recommendationId } = data;
              return { mediaList, recommendationId };
            });
        }

        return Promise.reject(response);
      })
      .catch(reason => Promise.reject(reason));
  }

  /**
   * Get media composition by URN.
   *
   * @param {String} urn urn:rts:video:9800629
   * @param {Boolean} onlyChapters
   * @returns {Object} media composition json object
   */
  getMediaCompositionByUrn(urn, onlyChapters = false) {
    const url = `https://${this.baseUrl}mediaComposition/byUrn/${urn}.json?onlyChapters=${onlyChapters}&vector=portalplay`;

    const diagnostic = { url };

    return fetch(url)
      .then((response) => {
        diagnostic.httpStatusCode = response.status;
        diagnostic.httpStatusText = response.statusText;

        if (response.ok) {
          return response.json()
            .then((data) => {
              const mediaComposition = Object.assign(
                new MediaComposition(), data, { onlyChapters },
              );

              return {
                mediaComposition,
                diagnostic,
              };
            });
        }

        return Promise.reject(diagnostic);
      })
      .catch((reason) => {
        diagnostic.httpStatusText = reason.message;

        return Promise.reject(diagnostic);
      });
  }

  /**
   * Get a list appropriate for end screen display (Recommended list or Trending list).
   *
   * @param {String} bu RSI/RTR/SRF/RTS
   * @param {String} mediaType video or audio
   * @param {String} id the media ID
   * @param {Number} pageSize number of returned items
   */
  getEndScreenList(bu, mediaType, id, pageSize = 18) {
    const buToLowerCase = bu.toLowerCase();

    if (buToLowerCase === 'srf' && mediaType.toLowerCase() === 'video') {
      return this.getRecommendedList(buToLowerCase, id, pageSize);
    }

    return this.getTrendingList(buToLowerCase, mediaType, pageSize);
  }

  /**
   * Get a recommended list.
   *
   * @param {String} bu RSI/RTR/SRF/RTS
   * @param {String} id the media ID
   * @param {Number} pageSize number of returned items
   */
  getRecommendedList(bu, id, pageSize = 18) {
    // TODO use the recommended by urn instead
    // http://www.srfcdn.ch/developer-docs/integrationlayer/api/public/swagger/index.html#/Recommendation/MediaListRecommendedByUrn
    const url = `https://${this.baseUrl}${bu.toLowerCase()}/mediaList/video/recommended/${id}.json?pageSize=${pageSize}`;

    return DataProviderService.fetchMediaList(url);
  }

  /**
   * Get a trending list.
   *
   * @param {String} bu RSI/RTR/SRF/RTS
   * @param {String} mediaType video or audio
   * @param {Number} pageSize number of returned items
   */
  getTrendingList(bu, mediaType = 'video', pageSize = 18) {
    const url = `https://${this.baseUrl}${bu.toLowerCase()}/mediaList/${mediaType.toLowerCase()}/trending.json?pageSize=${pageSize}`;

    return DataProviderService.fetchMediaList(url);
  }
}

export default DataProviderService;
